// 3rd-party modules
import { Badge, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

// project modules
import Button from '../shared/button';
import { apiCall } from '../../helpers/apiHelper';
import { SvgLoader } from '../svg';

import * as SupportTicketApi from '../../apis/supportTicketApi';

import { SupportTicket } from "../../models/supportTicket";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from '../../models/dataSourceRequest';

export default function HeaderNotifications() {
  const [loading, setLoading] = useState(false);
  const [openSupportTicketsCount, setOpenSupportTicketsCount] = useState(0);
  let abortController = new AbortController();

  useEffect(() => {
    const getOpenSupportTicketsAsync = async () => {
      setLoading(true);

      const openSupportTickets = await getOpenSupportTicketsCount([{fieldName: "status", fieldValue: "active"}], undefined, undefined, abortController.signal);

      setOpenSupportTicketsCount((openSupportTickets?.length || 0));
      setLoading(false);
    };

    getOpenSupportTicketsAsync();

    return () => { abortController.abort(); }
  },[]);

  const getOpenSupportTicketsCount = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    const request = { conditionList, sortList, pagination } as DataSourceRequest;

    setLoading(true);
    const response = await apiCall(SupportTicketApi.getSupportTickets(request, abortSignal));
    setLoading(false);

    return response.success ? SupportTicket.toArrayOfClass(response.data?.value || []) : [];
  };

  return (
    <>
      <Tooltip placement="bottom" title="Open Support tickets">
        <Badge count={!loading ? openSupportTicketsCount : null}>
          { loading &&
            <icon><SvgLoader /></icon>
          }
          { !loading &&
            <Link to="/support/help-desk">
              <Button micro icon="notifications" />
            </Link>
          }
        </Badge>
      </Tooltip>
    </>
  );
}
