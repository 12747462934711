// 3rd-party modules
import { RouteObject, useRoutes } from 'react-router-dom';

// project modules
import AccountListPage from './pages/accounts/AccountListPage';
import AdminFilterSetsPage from './pages/support/AdminFilterSetsPage';
import ArchivePage from './pages/support/ArchivePage';
import AuthLayout from './layouts/AuthLayout';
import DefaultLayout from './layouts/DefaultLayout';
import EmptyLayout from './layouts/EmptyLayout';
import HelpDeskPage from './pages/support/HelpDeskPage';
import ImportDevicesPage from './pages/admin/ImportDevices';
import IndexPage from './pages/IndexPage';
import LoginPage from './pages/auth/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
// import PostListPage from './pages/admin/PostListPage';
import ProtectedRoute from './components/auth/ProtectedRoute';
import RevenueSummaryPage from './pages/admin/RevenueSummaryPage';
import SupportDashboardPage from './pages/support/SupportDashboardPage';
import SystemUserPage from './pages/admin/SystemUserPage';
import SettingsPage from './pages/admin/SettingsPage';
import SalesBillingPage from './pages/sales/SalesBillingPage';
import SalesDashboardPage from './pages/sales/SalesDashboardPage';
import TemplatePage from './pages/template';

export default function AppRouter() {
  const routes: RouteObject[] = [
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      { path: 'login', element: <LoginPage /> },
    ]
  },
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
      { path: '', element: <ProtectedRoute><IndexPage /></ProtectedRoute> },
    ]
  },
  {
    path: '/accounts',
    element: <DefaultLayout />,
    children: [
      { path: '', element: <ProtectedRoute><AccountListPage /></ProtectedRoute> },
    ]
  },
  {
    path: '/sales',
    element: <DefaultLayout />,
    children: [
      { path: '', element: <ProtectedRoute><SalesDashboardPage /></ProtectedRoute> },
      { path: 'billing', element: <ProtectedRoute><SalesBillingPage /></ProtectedRoute> }
    ]
  },
  {
    path: '/support',
    element: <DefaultLayout />,
    children: [
      { path: '', element: <ProtectedRoute><SupportDashboardPage /></ProtectedRoute> },
      { path: 'help-desk', element: <ProtectedRoute><HelpDeskPage /></ProtectedRoute> },
      { path: 'filter-sets', element: <ProtectedRoute><AdminFilterSetsPage /></ProtectedRoute> },
      { path: 'help-desk', element: <ProtectedRoute><IndexPage /></ProtectedRoute> },
      { path: 'email', element: <ProtectedRoute><IndexPage /></ProtectedRoute> },
      { path: 'archive', element: <ProtectedRoute><ArchivePage /></ProtectedRoute> }
    ]
  },
  {
    path: '/admin',
    element: <DefaultLayout />,
    children: [
      { path: 'system-users', element: <ProtectedRoute roles={['System-Admin', 'System-User']}><SystemUserPage /></ProtectedRoute> },
      // { path: 'posts', element: <ProtectedRoute roles={['System-Admin', 'System-User']}><PostListPage /></ProtectedRoute> },
      { path: 'settings', element: <ProtectedRoute roles={['System-Admin', 'System-User']}><SettingsPage /></ProtectedRoute> },
      { path: 'revenue-summary', element: <ProtectedRoute roles={['System-Admin', 'System-User']}><RevenueSummaryPage /></ProtectedRoute> },
      { path: 'import-devices', element: <ProtectedRoute roles={['System-Admin', 'System-User']}><ImportDevicesPage /></ProtectedRoute> }
    ]
  },
  {
    index: true,
    element: <ProtectedRoute><DefaultLayout><IndexPage /></DefaultLayout></ProtectedRoute>
  }
];

  if (process.env.NODE_ENV === 'development') {
    routes.push({
        path: '/template',
        element: <EmptyLayout />,
        children: [
          { path: '', element: <TemplatePage /> }
        ]
    });
  }

  routes.push({
    path: '*',
    element: <NotFoundPage />
  });

  return useRoutes(routes);
}
